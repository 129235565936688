class Guard {
  vue;

  store;

  router;

  unsubscribe;

  waitingChecks = [];

  constructor(store, router) {
    this.store = store;
    this.router = router;
    this.waitingChecks = [];
  }

  async ensureGranted(perm, shouldNotify = false, any = false) {
    // console.debug(`ensureGranted(${perm}): call`);
    if (Object.keys(this.store.state.permissions).length === 0) { // perms not loaded
      // console.debug(`ensureGranted(${perm}): enqueued`);
      let granted;
      let denied;
      const promise = new Promise((resolve, reject) => {
        granted = resolve;
        denied = reject;
      });
      this.waitingChecks.push({
        perm,
        shouldNotify,
        granted,
        denied,
        any,
      });
      return promise;
    }
    let success = false;
    if (Array.isArray(perm)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const p of perm) {
        if (this.store.state.permissions[p] === true) {
          if (any) {
            success = true;
            break;
          }
        } else if (!any) {
          success = false;
          break;
        }
      }
    } else {
      success = this.store.state.permissions[perm] === true;
    }
    if (success) {
      // console.debug(`ensureGranted(${perm}): resolve immediate`);
      return Promise.resolve(true);
    }
    // console.debug(`ensureGranted(${perm}): reject immediate`);
    const message = `Not granted with '${perm}' permission`;
    this.deniedActions(message, shouldNotify);
    return Promise.reject(message);
  }

  onPermsChanged(permissions) {
    // eslint-disable-next-line no-restricted-syntax
    for (const check of this.waitingChecks) {
      let success = false;
      if (Array.isArray(check.perm)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const p of check.perm) {
          if (permissions[p] === true) {
            if (check.any) {
              success = true;
              break;
            }
          } else if (!check.any) {
            success = false;
            break;
          }
        }
      } else {
        success = permissions[check.perm] === true;
      }
      if (success) {
        // console.debug(`ensureGranted(${check.perm}): resolve postponed`);
        check.granted(true);
      } else {
        // console.debug(`ensureGranted(${check.perm}): reject postponed`);
        const message = `Not granted with '${check.perm}' permission`;
        check.denied(message);
        this.deniedActions(message, check.shouldNotify);
      }
    }
  }

  deniedActions(message, shouldNotify) {
    if (shouldNotify) {
      this.store.dispatch('showSnackbar', { text: message, color: 'red darken-2' });
    }
  }

  install(vue) {
    this.vue = vue;
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$ensureGranted = this.ensureGranted.bind(this);
    // eslint-disable-next-line no-unused-vars
    this.unsubscribe = this.store.subscribe((mutation, state) => {
      if (mutation.type !== 'SET_PERMISSIONS') {
        return;
      }
      this.onPermsChanged(state.permissions);
    });
  }
}

export default Guard;
