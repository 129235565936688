import * as mask from 'mask-sensitive';
import { mapState } from 'vuex';
import globalStore from '../store';

const MaskSensitive = {
  sensitive(str) {
    return String(str).split(' ').map((s) => mask(s, { mode: 'middle' })).join(' ');
  },
  secret(str) {
    return mask(str);
  },
};

const store = {
  state: {
    hidden: true,
    initialized: false,
  },

  mutations: {
    SET_MASK_SENSITIVE(state, val) {
      state.hidden = val;
    },
    SET_INITIALIZED(state) {
      state.initialized = true;
    },
  },
};

const mixin = {
  computed: {
    ...mapState({
      initialized: (s) => s.maskSensitive.initialized,
    }),

    maskedSensitive() {
      if (!this.initialized) {
        this.lazyInit();
      }
      return this.$store.state.maskSensitive.hidden;
    },
  },

  methods: {
    sensitive(str) {
      this.lazyInit();
      if (!this.maskedSensitive) {
        return str;
      }
      return MaskSensitive.sensitive(str);
    },

    secret(str) {
      this.lazyInit();
      if (!this.maskedSensitive) {
        return str;
      }
      return MaskSensitive.secret(str);
    },

    setMaskSensitive(val) {
      this.$store.commit('SET_MASK_SENSITIVE', Boolean(val));
      localStorage.maskSensitive = JSON.stringify(this.maskedSensitive);
    },

    /**
     * lazyInit method is necessary because this maskSensitive mixin used globally in EVERY component,
     * even if component actually doesn't want this mixin's features
     */
    lazyInit() {
      if (this.initialized) {
        return;
      }
      if ('maskSensitive' in localStorage) {
        this.$store.commit('SET_MASK_SENSITIVE', Boolean(JSON.parse(localStorage.maskSensitive)));
      }
      this.$store.commit('SET_INITIALIZED');
    },
  },
};

export default {
  install(Vue) {
    globalStore.registerModule('maskSensitive', store);
    Vue.mixin(mixin); // register globally
  },
};
