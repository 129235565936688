import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);
export default {
  install(v) {
    // eslint-disable-next-line no-param-reassign
    v.prototype.$errorToast = (message) => v.prototype.$toast.error(message, {
      type: 'error',
      dismissible: true,
      duration: 5000,
    });
  },
};
