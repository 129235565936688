import Vue from 'vue';
import Vuex from 'vuex';
import { get } from 'lodash';
import { getDocs, collection } from 'firebase/firestore';
import { AccountTypeClient, AccountTypeCareProvider } from '@/helpers/const';
import userCareKit from './user-care-kit';

Vue.use(Vuex);

// eslint-disable-next-line no-unused-vars
function firestoreDocsToObject(docs) {
  return docs.reduce((carry, item) => ({
    ...carry,
    [item.id]: {
      id: item.id,
      ...item.data(),
    },
  }), {});
}

function firestoreDocsToArray(docs) {
  return docs.reduce((carry, item) => [
    ...carry,
    {
      id: item.id,
      ...item.data(),
    },
  ], []);
}

export default new Vuex.Store({
  state: {
    drawer: true,
    user: null,
    dbUser: {},
    users: [],
    usersCounters: {},
    permissions: {},
    care_kits: [],
    trial_states: ['started', 'ended', 'passed'],
    care_kits_options: [],
    admins: [],
    roles: {},
    refcode_sets: [],
    vendors: [],
    insurances: [],
    globalSnackbar: {
      enabled: false,
      timeout: 3000,
      text: '',
    },
  },

  mutations: {
    TOGGLE_DRAWER(state) {
      state.drawer = !state.drawer;
    },
    SET_FB_USER(state, user) {
      Vue.set(state, 'user', user);
    },
    SET_USERS(state, users) {
      Vue.set(state, 'users', users);
    },
    PATCH_USER(state, { uid, data }) {
      let user = state.users.find((u) => u.uid === uid);
      if (user) {
        user = Object.assign(user, data);
        state.users = [
          ...state.users.filter((u) => u.uid !== uid),
          user,
        ];
      }
    },
    SET_PERMISSIONS(state, permissions) {
      Vue.set(state, 'permissions', permissions || {});
    },
    SET_DB_USER(state, dbUser) {
      Vue.set(state, 'dbUser', dbUser);
    },
    SET_CARE_KITS(state, data) {
      Vue.set(state, 'care_kits', data || {});
    },
    SET_CARE_KITS_OPTIONS(state, data) {
      Vue.set(state, 'care_kits_options', data || {});
    },
    SET_ADMIN_LIST(state, admins) {
      Vue.set(state, 'admins', admins);
    },
    SET_ROLES(state, roles) {
      Vue.set(state, 'roles', roles);
    },
    UPDATE_USER_ACL(state, { uid, roles, perms }) {
      const user = state.admins.find((u) => u.uid === uid);
      if (user) {
        user.perms = perms;
        user.roles = roles;
      }
    },
    SET_USERS_COUNTERS(state, data) {
      Vue.set(state, 'usersCounters', data);
    },
    SET_REFODE_SETS(state, data) {
      Vue.set(state, 'refcode_sets', data);
    },
    SET_VENDORS(state, data) {
      Vue.set(state, 'vendors', data);
      // state.vendors.unshift({
      //   id: null,
      //   name: '—',
      // });
    },
    SET_INSURANCES(state, data) {
      Vue.set(state, 'insurances', data);
      // state.insurances.unshift({
      //   id: null,
      //   name: '—',
      // });
    },
    OPEN_SNACKBAR(state, { text, timeout, color }) {
      state.globalSnackbar.text = text;
      state.globalSnackbar.timeout = timeout;
      if (color) {
        state.globalSnackbar.color = color;
      }
      state.globalSnackbar.enabled = true;
    },
    HIDE_SNACKBAR(state) {
      state.globalSnackbar.enabled = false;
    },
  },

  actions: {
    async fetchUsers({ commit }) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$api('usersList').then(({ data }) => {
        commit('SET_USERS', data);
      });
    },

    async fetchCareKits({ commit }) {
      const a = getDocs(collection(Vue.$firestore, '/care_kits')).then((docs) => {
        commit('SET_CARE_KITS', firestoreDocsToArray(docs.docs));
      });
      const b = getDocs(collection(Vue.$firestore, '/care_kits_options')).then((docs) => {
        commit('SET_CARE_KITS_OPTIONS', firestoreDocsToArray(docs.docs));
      });
      return Promise.allSettled([a, b]);
    },

    async fetchAdmins({ commit }) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$api('adminList').then(({ data }) => {
        commit('SET_ADMIN_LIST', data.admins);
        commit('SET_ROLES', data.roles);
      });
    },

    async fetchUsersCounters({ commit }) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$api('fetchUsersCounters').then(({ data }) => {
        commit('SET_USERS_COUNTERS', data);
      });
    },

    async fetchRefcodeSets({ commit }) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$api('fetchRefcodeSets').then(({ data }) => {
        commit('SET_REFODE_SETS', data);
      });
    },

    async fetchVendors({ commit }) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$api('getVendors').then(({ data }) => {
        commit('SET_VENDORS', data.vendors ?? []);
      });
    },

    async fetchInsurances({ commit }) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$api('getInsurances').then(({ data }) => {
        commit('SET_INSURANCES', data.insurances ?? []);
      });
    },

    showSnackbar({ commit }, { text, timeout, color }) {
      commit('OPEN_SNACKBAR', { text, timeout, color });
    },

    hideGlobalSnackbar({ commit }) {
      commit('HIDE_SNACKBAR');
    },
  },

  getters: {
    authenticated: (state) => state.user !== null,
    user: (state) => (uid) => state.users.find((u) => u.uid === uid),
    granted: (state) => (...permissions) => permissions.find((p) => !state.permissions[p]) === undefined,
    grantedAny: (state) => (...permissions) => permissions.find((p) => state.permissions[p] === true) !== undefined,
    patients: (state) => state.users.filter((u) => get(u, 'accountType') === AccountTypeClient),
    careProviders: (state) => state.users.filter((u) => get(u, 'accountType') === AccountTypeCareProvider),
    vendor: (state) => (vendorId) => state.vendors.find((v) => v.id === vendorId) ?? { name: '—' },
    insurance: (state) => (id) => state.insurances.find((v) => v.id === id) ?? { name: '—' },
    careKit: (state) => (careKit) => state.care_kits.find((id) => id === careKit),
  },

  modules: {
    userCareKit,
  },
});
