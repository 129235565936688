import Vue from 'vue';

export default {
  state: () => ({
    carePlan: {},
  }),

  actions: {
    fetchUserCarePlan({ state }, uid) {
      return Vue.prototype.$api('getUserCarePlan', uid).then(({ data }) => {
        if (!Object.keys(data).length) {
          return;
        }
        const plan = data;
        plan.lastPaymentDate = new Date(plan.lastPaymentDate);
        plan.nextPaymentDate = new Date(plan.nextPaymentDate);
        plan.firstPaymentDate = plan.firstPaymentDate ? new Date(plan.firstPaymentDate) ?? null : null;
        Vue.set(state, 'carePlan', {
          ...data,
          ...plan,
        });
      });
    },
  },
};
