export const AccountTypeCareProvider = 'care_provider';
export const AccountTypeClient = 'client';
export const AccountTypeInsuranceRepresentative = 'insurance_representative';
export const AccountTypeVendorAdmin = 'vendor_admin';
export const ZestReverseCarePlanID = 'zest_pro';
export const ZestMonitorCarePlanID = 'zest_monitor';
export const ZestEatWellCarePlanID = 'eat_well';
export const DateTimeFormat = 'DD-MM-YYYY HH:mm';

export const biomarkerNameMap = {
  bloodPressure_mmhg: 'BP',
  fastingPlasmaGlucoseTest_mgld: 'FBS',
  hemoglobinA1cTest_percentages: 'HbA1c',
  oralGlucoseToleranceTest_mgld: 'OGTT',
  postprandial2hoursTest_mgld: '2HPP',
  randomGlucoseTest_mgld: 'RBS',
  waist_cm: 'Waist',
  weight_kg: 'Weight',
  ldl_cholesterol_mgld: 'LDL Cholesterol',
};

export const knownPermissions = {
  //
  readConsultationReports_vendor: 'readConsultationReports_vendor',
  readConsultationReports_insurance: 'readConsultationReports_insurance',
  readConsultationReports_all: 'readConsultationReports_all',
  //
  subscriptions_vendor: 'subscriptions_vendor',
  subscriptions_insurance: 'subscriptions_insurance',
  subscriptions_all: 'subscriptions_all',
  //
  updateUser_all: 'updateUser_all',
  updateUser_vendor: 'updateUser_vendor',
  updateUser_insurance: 'updateUser_insurance',
  //
  listUsers_vendor: 'listUsers_vendor',
  listUsers_insurance: 'listUsers_insurance',
  listUsers_all: 'listUsers_all',
  //
  //
  readPricing_vendor: 'readPricing_vendor',
  readPricing_all: 'readPricing_all',
  //
  updatePricing_vendor: 'updatePricing_vendor',
  updatePricing_all: 'updatePricing_all',
  //
  biomarkers_vendor: 'biomarkers_vendor',
  biomarkers_insurance: 'biomarkers_insurance',
  biomarkers_all: 'biomarkers_all',
  //
  userInfo: 'userInfo_vendor',
  userInfo_insurance: 'userInfo_insurance',
  userInfo_all: 'userInfo_all',
  //
  wipeUsers_vendor: 'wipeUsers_vendor',
  wipeUsers_all: 'wipeUsers_all',
  //
  sendReports_all: 'sendReports_all',
  //
  listCareProviders_all: 'listCareProviders_all',
  listCareProviders_vendor: 'listCareProviders_vendor',
  //
  manageAcl_vendor: 'manageAcl_vendor',
  manageAcl_all: 'manageAcl_all',
  manageAcl_insurance: 'manageAcl_insurance',
  //
  payments_vendor: 'payments_vendor',
  payments_insurance: 'payments_insurance',
  payments_all: 'payments_all',
  //
  refcodes_vendor: 'refcodes_vendor',
  refcodes_all: 'refcodes_all',
  //
  biomarkersOverview_vendor: 'biomarkersOverview_vendor',
  biomarkersOverview_insurance: 'biomarkersOverview_insurance',
  biomarkersOverview_all: 'biomarkersOverview_all',
  //
  canAccessAdmin: 'canAccessAdmin',
  usersStatistic: 'usersStatistic',
  toggleCareProviderMembership: 'toggleCareProviderMembership',
  toggleTestFlag: 'toggleTestFlag',
  readClientCareTeam: 'readClientCareTeam',
  assignClientCareTeam: 'assignClientCareTeam',
  sendReports: 'sendReports',
  coaching: 'coaching',
  fullAccess: 'fullAccess',
  admin: 'admin',
  createUsers: 'createUsers',
  promoCodes: 'promoCodes',
  listVendors: 'listVendors',
  writeVendors: 'writeVendors',
  listInsurances: 'listInsurances',
  manageUserReferrals_all: 'manageUserReferrals_all',
};
