import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import firebase from './plugins/firebase';
import toast from './plugins/toast';
import './plugins/sweetalert';
import './plugins/apexcharts';
import './plugins/luxon';
import store from './store';
import router from './plugins/router';
import Guard from './plugins/guard';
import vueHeap from './plugins/vueHeap';
import maskSensitive from './plugins/maskSensitive';
import './assets/scss/styles.scss';

Vue.config.productionTip = false;
Vue.use(vueHeap, { envId: process.env.VUE_APP_HEAP_ENV_ID, enable: true });
Vue.use(firebase);
Vue.use(maskSensitive);
Vue.use(new Guard(store, router));
new Vue({
  toast,
  router,
  store,
  vuetify,
  render: (create) => create(App),
}).$mount('#app');
