import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2);
Vue.use({
  install(v) {
    // eslint-disable-next-line no-param-reassign
    v.prototype.$confirm = (text, title = '') => v.prototype.$swal({
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      text,
      title,
    }).then(({ isConfirmed }) => isConfirmed);
  },
});
