const HEAP_INSTANCE_METHODS = [
  'addEventProperties',
  'addUserProperties',
  'clearEventProperties',
  'identify',
  'resetIdentity',
  'removeEventProperty',
  'setEventProperties',
  'track',
  'unsetEventProperty',
];

export default {
  createHeap(envId, config) {
    // initialize heap if necessary
    window.heap = window.heap || [];

    // set configuration properties
    window.heap.appid = envId;
    window.heap.config = config || {};

    // while we are waiting for the heap script to load we still need to be
    // able to capture any events that are meant to be logged by heap. once the
    // script is loaded, these events will be uploaded to heap
    for (let i = 0; i < HEAP_INSTANCE_METHODS.length; i += 1) {
      window.heap[HEAP_INSTANCE_METHODS[i]] = (...args) => {
        if (window.heap && window.heap.push) {
          window.heap.push(
            [HEAP_INSTANCE_METHODS[i]].concat(
              Array.prototype.slice.call(args, 0),
            ),
          );
        }
      };
    }
  },
  addHeapScript(envId, config) {
    const useHTTPS = config.forceSSL || document.location.protocol === 'https:';
    const heapScript = document.createElement('script');
    heapScript.type = 'text/javascript';
    heapScript.async = true;
    heapScript.src = `${useHTTPS ? 'https:' : 'http:'}//cdn.heapanalytics.com/js/heap-${envId}.js`;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(heapScript, firstScriptTag);
  },
  install(v, options) {
    if (options.envId === undefined) {
      console.log('No Heap App ID specified.');
      return;
    }
    // create a heap instance
    this.createHeap(options.envId, options);

    if (options.enable) {
      // load the heap script onto the page
      this.addHeapScript(options.envId, options);
    }
    // add $heap reference so components can use this.$heap
    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      v.prototype.$heap = (method, payload) => {
        if (window.heap[method]) {
          return window.heap[method](payload);
        }
        if (window.heap.push) {
          return window.heap.push([method, payload]);
        }
      };
    }, 0);
  },
};
