import Vue from 'vue';
import VueRouter from 'vue-router';
import { compact, concat } from 'lodash';

const Home = () => import(/* webpackChunkName: "home" */ '@/pages/Home');
const Login = () => import(/* webpackChunkName: "login" */ '@/pages/Login');
const Profile = () => import(/* webpackChunkName: "profile" */ '@/pages/Profile');
const Reports = () => import(/* webpackChunkName: "profile" */ '@/pages/Reports');

const Patients = () => import(/* webpackChunkName: "users-index" */ '@/pages/Users/Patients');
const User = () => import(/* webpackChunkName: "users-user" */ '@/pages/Users/User/User');
const UserBasic = () => import(/* webpackChunkName: "users-basic" */ '@/pages/Users/User/Basic');
const UserBiomarkers = () => import(/* webpackChunkName: "users-biomarkers" */ '@/pages/Users/User/Biomarkers');
const UserAssessments = () => import(/* webpackChunkName: "users-assessments" */ '@/pages/Users/User/Assessments');

const CareProviders = () => import(/* webpackChunkName: "users-care-providers" */ '@/pages/Users/CareProviders');
const CareProvider = () => import(/* webpackChunkName: "users-care-provider" */ '@/pages/Users/CareProvider/CareProvider');
const CareProviderBasic = () => import(/* webpackChunkName: "users-care-provider-basic" */ '@/pages/Users/CareProvider/Basic');

const Admins = () => import('@/pages/Admins/Index');
const AdminUser = () => import('@/pages/Admins/AdminUser');

const Refcodes = () => import(/* webpackChunkName: "refcodes" */ '@/pages/Refcodes');

const Coaching = () => import(/* webpackChunkName: "coaching" */ '@/pages/Coaching/Coaching');
const UserCoaching = () => import(/* webpackChunkName: "user-coaching" */ '@/pages/Coaching/UserCoaching');
const UserCoachingBasic = () => import(/* webpackChunkName: "user-coaching-basic" */ '@/pages/Coaching/UserCoachingBasic');
const UserCoachingCalendar = () => import(/* webpackChunkName: "user-coaching-calendar" */ '@/pages/Coaching/UserCoachingCalendar');
const UserSubscriptions = () => import(/* webpackChunkName: "user-subscriptions" */ '@/pages/Users/User/Subscriptions');
const BiomarkersOverview = () => import(/* webpackChunkName: "Biomarkers-overview" */ '@/pages/BiomarkersOverview');
const Payments = () => import(/* webpackChunkName: "payments" */ '@/pages/Payments');
const MigrationAssistant = () => import(/* webpackChunkName: "migration-assistant" */ '@/pages/MigrationAssistant');
const PromoCodes = () => import(/* webpackChunkName: "promo-codes" */ '@/pages/PromoCodes');
const Pricing = () => import(/* webpackChunkName: "pricing" */ '@/pages/Pricing');
const Consultations = () => import(/* webpackChunkName: "consultations" */ '@/pages/Consultations');
const Vendors = () => import(/* webpackChunkName: "vendors" */ '@/pages/Vendors/Index.vue');

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Home },
  { path: '/login', component: Login },
  { path: '/profile', component: Profile },
  {
    path: '/users',
    component: Patients,
    props: (route) => route.query,
    name: 'users',
  },
  {
    path: '/users/:uid',
    component: User,
    children: [{
      path: '',
      component: UserBasic,
    }, {
      path: 'basic',
      component: UserBasic,
    }, {
      path: 'biomarkers',
      component: UserBiomarkers,
    }, {
      path: 'subscriptions',
      component: UserSubscriptions,
    }, {
      path: 'assessments',
      component: UserAssessments,
    }],
  },
  { path: '/care-providers', component: CareProviders },
  {
    path: '/care-providers/:uid',
    component: CareProvider,
    children: [{
      path: '',
      component: CareProviderBasic,
    }],
  },
  { path: '/admins', component: Admins },
  { path: '/admins/:uid', component: AdminUser },
  { path: '/reports', component: Reports },
  { path: '/refcodes', component: Refcodes },
  { path: '/coaching', component: Coaching },
  { path: '/payments/:paymentType', component: Payments },
  { path: '/migration-assistant', component: MigrationAssistant },
  { path: '/promo-codes', component: PromoCodes },
  { path: '/pricing', component: Pricing },
  { path: '/consultations', component: Consultations },
  { path: '/vendors', component: Vendors },
  {
    path: '/coaching/:uid',
    component: UserCoaching,
    children: [{
      path: '',
      component: UserCoachingBasic,
    }, {
      path: 'basic',
      component: UserCoachingBasic,
    }, {
      path: 'calendar',
      component: UserCoachingCalendar,
    }],
  },
  {
    path: '/biomarkers-overview',
    component: BiomarkersOverview,
    props: (route) => {
      const data = route.query;
      data.include_expired = Boolean(data.include_expired) ?? null;
      ['biomarkers', 'plans', 'weeks'].forEach((key) => {
        if (data[key]) {
          data[key] = compact(concat(data[key]));
          if (key === 'weeks') {
            data[key] = data[key].map((v) => Number(v));
          }
        }
      });
      return data;
    },
    name: 'biomarkersOverview',
  },
];

const router = new VueRouter({
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.path !== '/login' && !router.app.$store.getters.authenticated) {
    next('/login');
    return;
  }
  next();
});

export default router;
