import Vue from 'vue';
import Luxon from 'vue-luxon';

Vue.use(Luxon, {
  input: {
    // format: 'iso',
  },
  output: {
    format: 'DATE_MED',
    locale: 'en',
  },
});
